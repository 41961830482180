import { Form, Modal, Select, Table } from "antd";
import React, { useState } from "react";
import { CropsOptions } from "../../crop/CropStatic";
import { SeasonsOptions } from "../../seasons/SeasonUtil";
import { FieldDB } from "../FIeldDB";
import { FieldModel } from "../FieldModel";

export const SetCultureModal: React.FC<{
  selected: React.Key[];
  rows: FieldModel[];
  open: boolean;
  onClose: () => void;
}> = ({ selected, rows, onClose, open }) => {
  const [season, setSeason] = useState<string>();
  const [crop, setCrop] = useState<string>();
  const setCropForFields = async () => {
    if (!season || !crop) return;
    const selectedFields = rows.filter((row) => selected.includes(row.id));
    selectedFields.forEach(async (field) => {
      if (!field.cropsBySeason) field.cropsBySeason = {};
      field.cropsBySeason[season] = crop;
      await FieldDB.update(field);
    });
    onClose();
  };
  return (
    <Modal
      title="Postavite kulturu"
      open={open}
      onClose={onClose}
      onCancel={onClose}
      okText="Postavi"
      onOk={setCropForFields}
    >
      <Form>
        <Form.Item label="Sezona" name="season">
          <Select
            showSearch
            placeholder="Izaberi sezonu"
            onChange={(value) => setSeason(value)}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={SeasonsOptions}
          />
        </Form.Item>
        <Form.Item label="Kultura" name="crop">
          <Select
            showSearch
            placeholder="Izaberi kulturu"
            onChange={(value) => setCrop(value)}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={CropsOptions}
          />
        </Form.Item>
      </Form>
      <Table
        size="small"
        pagination={false}
        columns={[
          {
            title: "Parcela",
            dataIndex: "name",
          },
        ]}
        dataSource={rows.filter((r) => selected.indexOf(r.id) !== -1)}
      />
    </Modal>
  );
};
