import { Card } from "antd";
import { Outlet, redirect } from "react-router";
import { Route } from "react-router-dom";
import MapComponent from "./components/MapComponent";
import { AppPage } from "./modules/app/AppPage";
import Login from "./modules/auth/Login";
import { farmLoader } from "./modules/farm/FarmDB";
import { FarmForm } from "./modules/farm/FarmForm";
import { FarmPage } from "./modules/farm/FarmPage";
import { fieldLoader } from "./modules/fields/FIeldDB";
import { FieldForm } from "./modules/fields/FieldForm";
import { FieldsPage } from "./modules/fields/FieldsPage";
import { NavigatePage } from "./modules/navigate/NavigatePage";
import { SeasonsPage } from "./modules/seasons/SeasonsPage";
import { Protected } from "./routes/Protected";

export const AppRoutes = (
  <Route>
    <Route
      element={
        <Protected>
          <Outlet />
        </Protected>
      }
    >
      <Route path="farm" loader={farmLoader}>
        <Route index element={<FarmPage />} />

        <Route path="fields" element={<FieldsPage />} />
        <Route
          path="fields/create"
          element={<FieldForm mode="create" showSubmit={false} />}
        />
        <Route
          path="fields/:id/edit"
          element={<FieldForm mode="update" showSubmit={false} />}
          loader={fieldLoader}
        />
        <Route
          path="fields/:id"
          element={<FieldForm mode="view" showSubmit={false} />}
          loader={fieldLoader}
        />

        <Route path="seasons" element={<SeasonsPage />} />
        <Route path="map" element={<MapComponent />} />
        <Route path="app" element={<AppPage />} />
        <Route path="navigate" element={<NavigatePage />} />
      </Route>

      <Route
        path="farm/create"
        element={
          <Card title="Napravi novo Gazdinstvo">
            <FarmForm mode="create" />
          </Card>
        }
      />

      {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}

      <Route
        path="*"
        loader={async () => {
          console.log("Redirecting to /farm");
          return redirect("/farm");
        }}
      />
    </Route>
    <Route path="/login" element={<Login />} />
  </Route>
);
