// src/BabylonScene.tsx
import React, { useEffect, useRef } from "react";
import { FieldDB } from "../fields/FIeldDB";
import { NavigateUI } from "./ui/NavigateUI";

const NavigateScreen: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const ui = useRef<NavigateUI | null>(null);
  const initFields = () => {
    FieldDB.all().then((fields) => {
      ui.current?.setFields(fields);
    });
  };

  useEffect(() => {
    if (!canvasRef.current) return;
    const UI = new NavigateUI(canvasRef.current);
    ui.current = UI;
    initFields();
    return () => UI.cleanup();
  }, []);

  return <canvas ref={canvasRef} style={{ width: "100%", height: "100vh" }} />;
};

export default NavigateScreen;
