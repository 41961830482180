import { Form, Input } from "antd";
import React from "react";
import { CrudForm, FormComponentProps } from "../../components/crud/CrudForm";
import { FieldSelect } from "../../components/crud/form/FieldSelect";
import { FieldSeasonDB } from "./FieldSeasonDB";
import { FieldSeasonModel } from "./FieldSeasonModel";

export const FieldSeasonForm: React.FC<FormComponentProps<FieldSeasonModel>> = (
  props
) => {
  return (
    <CrudForm<FieldSeasonModel>
      resource="/farm"
      titleKey="season"
      name="gazdinstvo"
      crudClass={FieldSeasonDB}
      {...props}
      // afterCreate={(createdModel) => {
      //   if (props.afterCreate) {
      //     props.afterCreate(createdModel);
      //   }
      // }}
    >
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: "Unesite naziv gazdinstva!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Description" name="description">
        <Input.TextArea />
      </Form.Item>
      <Form.Item label="Description" name="description">
        <FieldSelect />
      </Form.Item>
    </CrudForm>
  );
};
