import { useState } from "react";

/*
  This function returns the current farming season in format currentYear-nextYear
  And season starts from oktobar
*/
export const getCurrentSeason = () => {
  const date = new Date();
  const month = date.getMonth();
  const year = date.getFullYear();
  let key = `${year}-${year + 1}`;
  if (month < 9) {
    key = `${year - 1}-${year}`;
  }
  return key;
};
export const getSeasonLabel = (season: string) => {
  const [start, end] = season.split("-");
  return `${start}/${end}`;
};
// I need generate seasons from 2000 year and next 3 years in a future
export const generateSeasons = () => {
  const date = new Date();
  const year = date.getFullYear();
  let seasons = [];
  for (let i = 2000; i < year + 3; i++) {
    seasons.unshift({
      value: `${i}-${i + 1}`,
      label: `${i}/${i + 1}`,
    });
  }
  return seasons;
};
// export const generateSeasons = () => {
//   const date = new Date();
//   const year = date.getFullYear();
//   let seasons = [];
//   for (let i = year - 2000; i < year + 3; i++) {
//     seasons.push({
//       value: `${i}-${i + 1}`,
//       label: `${i}/${i + 1}`,
//     });
//   }
//   return seasons;
// };

export const SeasonsOptions = generateSeasons();

// I need to create react hook useSeason where I will return current season and also can change current season
export const useSeason = () => {
  const [season, setSeason] = useState(getCurrentSeason());
  return {
    season,
    setSeason,
  };
};
