import { featureCollection, polygon, union } from "@turf/turf";
import { FieldModel } from "./FieldModel";

export const sqMeterToHa = (sqMeter: number) => sqMeter / 10000;
export const haToSqMeter = (ha: number) => ha * 10000;
export const haToKj = (ha: number) => ha * 1.73772755977;
export const kjToHa = (kj: number) => kj / 1.73772755977;
export const sqMeterToKj = (sqMeter: number) => haToKj(sqMeterToHa(sqMeter));
export type AreaUnit = "ha" | "kj" | "ar" | "m2";

export const sqMeterToUnit = (sqMeter: number, unit: AreaUnit) =>
  unit === "ha"
    ? sqMeterToHa(sqMeter)
    : unit === "kj"
      ? sqMeterToKj(sqMeter)
      : sqMeter / 100;

//Should find longest border side, points are in order, each point is connected to the next one

export const findLongerBorderPath = (borders: google.maps.LatLng[]) => {
  let longestPath: google.maps.LatLng[] = [];
  let longestDistance = 0;
  for (let i = 0; i < borders.length - 1; i++) {
    const distance = google.maps.geometry.spherical.computeDistanceBetween(borders[i], borders[i + 1]);
    if (distance > longestDistance) {
      longestDistance = distance;
      longestPath = [borders[i], borders[i + 1]];
    }
  }
  return longestPath;

}
export const getHeadeing = (start: google.maps.LatLng, end: google.maps.LatLng) => {
  const heading = google.maps.geometry.spherical.computeHeading(start, end);
  return heading;
}

export const getFieldHeading = (field: FieldModel) => {
  const longestPath = findLongerBorderPath(getFieldLatLng(field));
  const heading = getHeadeing(longestPath[0], longestPath[1]);
  return heading;
}

export const getFieldLatLng = (field: FieldModel): google.maps.LatLng[] => {
  return JSON.parse(field.borders).map((point: number[]) => new google.maps.LatLng(point[1], point[0]));
}

const areaToTurfs = (field: FieldModel) => {
  return JSON.parse(field.borders);
}
export const turfToLatLng = (turf: any[][]) => {
  return turf.map((point) => ({ lat: point[1], lng: point[0] }));
}
const turfPolygon = (field: FieldModel) => {
  return polygon([areaToTurfs(field)]);
}
export const joinFieldsArea = (fields: FieldModel[]) => {
  const borders = fields.map((field) => turfPolygon(fields[0]));
  console.log("borders", borders);
  const unitedBorders = union(featureCollection(borders));
  if (!unitedBorders) return [];
  console.log("unitedBorders", unitedBorders);
  return turfToLatLng(unitedBorders?.geometry.coordinates[0]);
}