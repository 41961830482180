export const Crops = [
  { "key": "wheat", "title": "Wheat", "title_sr": "Pšenica" },
  { "key": "corn", "title": "Corn", "title_sr": "Kukuruz" },
  { "key": "sunflower", "title": "Sunflower", "title_sr": "Suncokret" },
  { "key": "soybean", "title": "Soybean", "title_sr": "Soja" },
  { "key": "barley", "title": "Barley", "title_sr": "Ječam" },
  { "key": "oats", "title": "Oats", "title_sr": "Ovas" },
  { "key": "sorghum", "title": "Sorghum", "title_sr": "Sirak" },
  { "key": "rye", "title": "Rye", "title_sr": "Raž" },
  { "key": "potato", "title": "Potato", "title_sr": "Krompir" },
  { "key": "tomato", "title": "Tomato", "title_sr": "Paradajz" },
  { "key": "onion", "title": "Onion", "title_sr": "Luk" },
  { "key": "carrot", "title": "Carrot", "title_sr": "Šargarepa" },
  { "key": "lettuce", "title": "Lettuce", "title_sr": "Zelena salata" },
  { "key": "cabbage", "title": "Cabbage", "title_sr": "Kupus" },
  { "key": "broccoli", "title": "Broccoli", "title_sr": "Brokoli" },
  { "key": "spinach", "title": "Spinach", "title_sr": "Spanać" },
  { "key": "pepper", "title": "Pepper", "title_sr": "Paprika" },
  { "key": "peanut", "title": "Peanut", "title_sr": "Kikiriki" },
  { "key": "rice", "title": "Rice", "title_sr": "Pirinač" },
  { "key": "cotton", "title": "Cotton", "title_sr": "Pamuk" },
  { "key": "sugarcane", "title": "Sugarcane", "title_sr": "Šećerna trska" },
];

export const CropsOptions = Crops.map((crop) => ({
  value: crop.key,
  label: crop.title_sr,
}));

export const findCrop = (key: string) => {
  return Crops.find((crop) => crop.key === key);
}