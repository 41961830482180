import { Select } from "antd";
import React, { useEffect } from "react";
import { FieldDB } from "../../../modules/fields/FIeldDB";

export const FieldSelect: React.FC<{
  id?: string;
  value?: string;
  onChange?: (value: string | string[] | undefined) => void;
}> = ({ value, onChange }) => {
  const [options, setOptions] = React.useState<any[]>([]);

  useEffect(() => {
    FieldDB.all().then((fields) => {
      setOptions(
        fields.map((field) => ({
          value: field.id,
          label: field.name,
        }))
      );
    });
  }, []);
  return (
    <Select options={options} value={value} onChange={(v) => onChange?.(v)} />
  );
};
