// src/BabylonScene.tsx
import React from "react";
import AppLayout from "../../components/AppLayout";
import NavigateScreen from "./NavigateScreen";

export const NavigatePage: React.FC = () => {
  return (
    <AppLayout>
      <NavigateScreen />
    </AppLayout>
  );
};
