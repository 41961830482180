import { ArcRotateCamera, Engine, MeshBuilder, Scene, Vector3 } from '@babylonjs/core';
import '@babylonjs/loaders';
import { GridMaterial, SkyMaterial } from '@babylonjs/materials';
import * as earcut from "earcut";
import { FieldModel } from '../../fields/FieldModel';

(window as any).earcut = earcut;

export class NavigateUI {
  private engine: Engine;
  private scene: Scene;

  constructor(private canvas: HTMLCanvasElement) {
    // Create the Babylon engine and scene
    this.engine = new Engine(this.canvas, true);
    this.scene = new Scene(this.engine);
    // Set up a basic camera
    const camera = new ArcRotateCamera("camera", Math.PI / 2, Math.PI / 4, 10, new Vector3(45, 5, 22), this.scene);
    camera.attachControl(canvas, true);

    const skyMaterial = new SkyMaterial("skyMaterial", this.scene);
    skyMaterial.backFaceCulling = false;
    skyMaterial.inclination = 0;

    const skybox = MeshBuilder.CreateBox("skyBox", { size: 1000.0 }, this.scene);
    skybox.material = skyMaterial;
    //ground 
    const ground = MeshBuilder.CreateGround("ground", { width: 1000, height: 1000 }, this.scene);
    ground.position.y = -0.01;
    // ground material 
    const groundMaterial = new GridMaterial("groundMaterial", this.scene);
    groundMaterial.majorUnitFrequency = 5;
    groundMaterial.minorUnitVisibility = 0.45;
    groundMaterial.gridRatio = 0.5;
    groundMaterial.backFaceCulling = false;
    ground.material = groundMaterial;
    // Render loop
    this.engine.runRenderLoop(() => {
      this.scene.render();
    });

    // Resize the engine when the window is resized
    window.addEventListener("resize", this.handleResize.bind(this));

  }
  cleanup() {
    window.removeEventListener("resize", this.handleResize);
    this.engine.dispose();
  }
  setFields(fields: FieldModel[]) {
    fields.forEach((field) => {
      if (field.borders) {
        const points = JSON.parse(field.borders).map((point: number[]) => new Vector3(point[1], 0, point[0]));
        console.log(points);
        const path = MeshBuilder.CreatePolygon("path", { shape: points }, this.scene);
        path.material = new GridMaterial("pathMaterial", this.scene);
        path.material.backFaceCulling = false;
        // path.material.colo

      }
    });
  }

  handleResize() {
    this.engine.resize()
  }
}