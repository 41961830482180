import { addDoc, CollectionReference, deleteDoc, doc, DocumentData, getDoc, getDocs, query, setDoc, where, WhereFilterOp } from "firebase/firestore";

type MyObject = {
  [key: string]: any;
};

function removeUndefinedKeys(obj: MyObject): MyObject {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, value]) => value !== undefined)
  );
}


export abstract class ModelDB<T extends { id?: string, createdAt: Date, updatedAt: Date }> {
  abstract collection(): Promise<CollectionReference<DocumentData>>;

  get getLastFetchTime(): number | null {
    return Number(localStorage.getItem(`lft-${this.constructor.name}`));
  }
  set setLastFetchTime(time: number | null) {
    if (time === null) {
      localStorage.removeItem(`lft-${this.constructor.name}`);
      return;
    }
    localStorage.setItem(`lft-${this.constructor.name}`, time.toString());
  }
  get fetchData(): T[] | null {
    const cahcedData = localStorage.getItem(`fd-${this.constructor.name}`);
    if (!cahcedData) {
      return null;
    }
    return JSON.parse(cahcedData);
  }
  set fetchData(data: T[] | null) {
    localStorage.setItem(`fd-${this.constructor.name}`, JSON.stringify(data));
  }


  async all(): Promise<T[]> {
    // if last fetch time is less than 5 minutes, return cached data
    // if (this.fetchData && this.getLastFetchTime && this.getLastFetchTime > new Date().getTime() - 5 * 60 * 1000) {
    //   return this.fetchData;
    // }
    const querySnapshot = await getDocs(await this.collection());
    this.fetchData = querySnapshot.docs.map((doc) => {
      return { ...doc.data(), id: doc.id } as T;
    });

    this.setLastFetchTime = new Date().getTime();
    return this.fetchData;

  }
  async where(constraints: { field: keyof T, operator: WhereFilterOp, value: any }[]): Promise<T[]> {

    const q = query(await this.collection(), ...constraints.map(c => where(c.field as string, c.operator, c.value)));
    const querySnapshot = await getDocs(q);

    return querySnapshot.docs.map((doc) => {
      return { ...doc.data(), id: doc.id } as T;
    });
  }
  async find(id: string): Promise<T | null> {
    // console.log("find", this.collection(), id);
    const docSnap = await getDoc(doc(await this.collection(), id));
    if (docSnap.exists()) {
      return { ...docSnap.data(), id: docSnap.id } as T;
    }
    return null;
  }
  async create(data: T): Promise<T> {
    if (data.id) return await this.update(data);

    const docRef = await addDoc(await this.collection(), removeUndefinedKeys(data));
    const model = { ...data, id: docRef.id };

    return data;
  }

  async update(data: T) {
    console.log(data);
    await setDoc(doc(await this.collection(), data.id), removeUndefinedKeys(data));
    return data;
  }

  async delete(data: T) {
    await deleteDoc(doc(await this.collection(), data.id));
  }
}

