import { FieldModel } from "../modules/fields/FieldModel";

type Coordinate = [number, number];

function generatePlacemark(field: FieldModel): string {
  const coordinates: Coordinate[] = JSON.parse(field.borders);
  const kmlCoordinates = coordinates.map(coord => [...coord, 0].join(',')).join(' ');

  return `
    <Placemark id="${field.id}">
      <name>${field.name}</name>
      <Polygon>
        <outerBoundaryIs>
          <LinearRing>
            <coordinates>${kmlCoordinates}</coordinates>
          </LinearRing>
        </outerBoundaryIs>
      </Polygon>
    </Placemark>
  `;
}

export function generateKml(fields: FieldModel[]): string {
  const kmlHeader = `<?xml version="1.0" encoding="UTF-8"?>
  <kml xmlns="http://www.opengis.net/kml/2.2">
    <Document>`;

  const kmlFooter = `
    </Document>
  </kml>`;

  const placemarks = fields.map(generatePlacemark).join('\n');

  return `${kmlHeader}${placemarks}${kmlFooter}`;
}