import React, { createContext, ReactNode, useContext, useState } from "react";
import { AreaUnit } from "./modules/fields/utils";
import { getCurrentSeason } from "./modules/seasons/SeasonUtil";

// Define the types for the values you want to store
interface AppContextType {
  season: string;
  setSeason: (season: string) => void;
  user: UserType | null;
  setUser: (user: UserType | null) => void;
  theme: string;
  setTheme: (theme: string) => void;
  toggleTheme: () => void;
  areaUnit: AreaUnit;
  setAreaUnit: (areaUnit: AreaUnit) => void;
}

// Define the user type as an example (you can customize this)
interface UserType {
  name: string;
  email: string;
}

// Create the context with an initial value (this will be overwritten by the provider)
const AppContext = createContext<AppContextType | undefined>(undefined);

const storageGet = (key: string) => {
  const item = localStorage.getItem(key);
  if (item) {
    return JSON.parse(item);
  }
  return null;
};
const storageSet = (key: string, value: any) => {
  localStorage.setItem(key, JSON.stringify(value));
};
// Define the provider component
export const AppProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  // State values
  const [season, setSeason] = useState<string>(
    storageGet("season") ?? getCurrentSeason()
  );
  const [user, setUser] = useState<UserType | null>(null);
  const [theme, setTheme] = useState<string>("light");
  const [areaUnit, setAreaUnit] = useState<AreaUnit>("ha");

  const setSeasonWithLocal = (season: string) => {
    setSeason(season);
    storageSet("season", season);
  };
  // Function to toggle the theme
  const toggleTheme = () =>
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));

  // Provide the state and functions to the children
  return (
    <AppContext.Provider
      value={{
        season,
        setSeason: setSeasonWithLocal,
        user,
        setUser,
        theme,
        setTheme,
        toggleTheme,
        areaUnit,
        setAreaUnit,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(AppContext);

  if (!context) {
    throw new Error("useAppContext must be used within an AppProvider");
  }

  return context;
};
