import { collection } from "firebase/firestore";
import { ModelDB } from "../../db/ModelDB";
import { db } from "../../firebase";
import { FarmDB } from "../farm/FarmDB";
import { FieldSeasonModel } from "./FieldSeasonModel";

class FieldSeasonDBClass extends ModelDB<FieldSeasonModel> {
  async collection() {

    return collection(
      db,
      "farms",
      (await FarmDB.getCurrentFarm())?.id!,
      "field-seasons"
    );
  }
}

export const FieldSeasonDB = new FieldSeasonDBClass();
