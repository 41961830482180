import { GoogleMap, Polygon, useJsApiLoader } from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
// import {
//   klindra,
//   randomNJ,
//   srpskaCrnja,
//   vojvodaStepa,
// } from "../data/map/coordinates";
import navArrowSvg from "../assets/nav_arrow.svg";
import { FieldModel } from "../modules/fields/FieldModel";
import { getFieldHeading } from "../modules/fields/utils";
import { createButton } from "./map/createButton";
const containerStyle = {
  width: "100%",
  height: "100%",
};
const parsePolygon = (polygon: string) => {
  return JSON.parse(polygon).map((p: number[]) => ({
    lat: p[1],
    lng: p[0],
  }));
};
const polygonOptions: google.maps.PolygonOptions = {
  fillColor: "yellow",
  fillOpacity: 0.1,
  strokeWeight: 1,
  strokeColor: "yellow",
};
export const MapComponent: React.FC<{
  fields?: FieldModel[];
  polygon?: string;
  editablePolygon?: boolean;
  autoCenter?: boolean;
}> = ({ autoCenter, fields, polygon }) => {
  const [mapHeading, setMapHeading] = useState(0);
  const [hoveredField, setHoveredField] = useState<FieldModel | null>();
  const [center, setCenter] = useState<{ lat: number; lng: number }>();
  const [libraries] = useState<any>(["marker", "geometry"]);
  const [myLocation, setMyLocation] = useState<{
    lat: number;
    lng: number;
    heading: number | null;
  }>();
  const [myMarker, setMyMarker] =
    useState<google.maps.marker.AdvancedMarkerElement>();
  const [myMarkerArrow, setMyMarkerArrow] = useState<HTMLImageElement>();

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCs7SLVAQ6RUgaUeTb4WcWDdX6swb7zbz8",
    libraries,
    version: "weekly",
    mapIds: ["b84d0b4186873aab"],
  });
  const [map, setMap] = React.useState<google.maps.Map | null>(null);
  let watchID: number;

  useEffect(() => {
    if ("geolocation" in navigator) {
      console.log("Geolocation is available in this browser.");
      watchID = navigator.geolocation.watchPosition(
        (position) => {
          setMyLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
            heading: position.coords.heading,
          });
        },
        (error) => {
          console.error(error);
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        }
      );
    } else {
      console.error("Geolocation is not available in this browser.");
    }

    // Cleanup function to stop watching when component unmounts
    return () => {
      navigator.geolocation.clearWatch(watchID);
    };
  }, []);
  useEffect(() => {
    if (map && !myMarker && myLocation) {
      const markerArrow = document.createElement("img");
      markerArrow.src = navArrowSvg;
      setMyMarkerArrow(markerArrow);
      const marker = new google.maps.marker.AdvancedMarkerElement({
        position: myLocation,
        map: map,
        content: markerArrow,
        title: "My Location",
      });
      setMyMarker(marker);
      // markerArrow.style.transform = `rotate(30deg)`;
      const mapHeading = map.getHeading() ?? 0;
      markerArrow.style.width = "50px";
      markerArrow.style.height = "50px";
      markerArrow.style.marginBottom = "-25px";
      markerArrow.style.scale = "1.5";
      markerArrow.style.transform = `rotate(${
        360 - (myLocation.heading ?? 0)
      }deg)`;
    }
    if (map && myMarker && myLocation && myMarkerArrow) {
      myMarker.position = myLocation;
      const mapHeading = map.getHeading() ?? 0;
      myMarkerArrow.style.transform = `rotate(${
        -mapHeading + (myLocation.heading ?? 0)
      }deg)`;
    }
  }, [myLocation, myMarker, map, mapHeading, myMarkerArrow]);

  const onLoad = React.useCallback(
    function callback(_map: google.maps.Map) {
      setMap(_map);
      initControls(_map);
    },
    [myLocation]
  );
  const initControls = (map: google.maps.Map) => {
    map.controls[google.maps.ControlPosition.LEFT_BOTTOM].clear();
    const findMeButton = createButton(() => {
      if (myLocation) map.setCenter(myLocation);
      console.log(map, myLocation);
    }, "/icons/near_me.svg");

    map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(findMeButton);
  };

  const onUnmount = React.useCallback(function callback(map: google.maps.Map) {
    setMap(null);
  }, []);
  const initFieldSideSizes = () => {
    console.log("Init field side sizes");
    if (!fields) return;

    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];
      if (!field.borders) return;
      const fieldPoints = JSON.parse(field.borders);
      if (fieldPoints.length < 2) return;
      for (let i = 0; i < fieldPoints.length; i++) {
        const pointA = new window.google.maps.LatLng(
          fieldPoints[i][1],
          fieldPoints[i][0]
        );
        const pointB = new window.google.maps.LatLng(
          fieldPoints[(i + 1) % fieldPoints.length][1],
          fieldPoints[(i + 1) % fieldPoints.length][0]
        );
        import("./map/PointsDistanceInfo").then((module) => {
          const pointsInfo = new module.PointsDistanceInfo(pointA, pointB);
          pointsInfo.setMap(map);
        });
      }
    }
  };

  useEffect(() => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds();

      if (polygon) {
        const points = JSON.parse(polygon);
        points.forEach((point: number[]) => {
          bounds.extend({ lat: point[1], lng: point[0] });
        });
      }
      if (fields && fields.length > 0) {
        fields.forEach((field) => {
          if (field.borders) {
            JSON.parse(field.borders).forEach((point: number[]) => {
              bounds.extend({ lat: point[1], lng: point[0] });
            });
          }
        });
      }
      setCenter(bounds.getCenter().toJSON());
      map.fitBounds(bounds);
      console.log("Fields, map", fields, map);
      if (fields) initFieldSideSizes();
    }
  }, [polygon, autoCenter, fields, map]);

  const focusField = (field: FieldModel) => {
    if (map && field.borders) {
      const bounds = new window.google.maps.LatLngBounds();
      JSON.parse(field.borders).forEach((point: number[]) => {
        bounds.extend({ lat: point[1], lng: point[0] });
      });
      map.fitBounds(bounds);
      setMapHeading(getFieldHeading(field) ?? 0);
    }
  };
  return isLoaded ? (
    <div style={{ width: "100%", height: "100%" }}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        options={{
          mapTypeId: "satellite",
          mapId: "b84d0b4186873aab",
          mapTypeControl: false,
          streetViewControl: false,
          fullscreenControl: true,
          fullscreenControlOptions: {
            position: google.maps.ControlPosition.RIGHT_BOTTOM,
          },
          heading: mapHeading,
          // zoomControl: false,
          // rotateControl: true,
        }}
        zoom={19}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        {/* Child components, such as markers, info windows, etc. */}
        <>
          {fields?.map((field) => {
            if (!field.borders) return null;

            let borders = parsePolygon(field.borders);
            // let children = fields.filter((f) => f.parentId === field.id);
            // if (children.length > 0) {
            //   borders = joinFieldsArea([field, ...children]);
            //   console.log("Joining fields", field.name, children, borders);
            // }
            return (
              <Polygon
                path={borders}
                key={field.id}
                onMouseOver={() => setHoveredField(field)}
                onMouseOut={() => setHoveredField(null)}
                options={polygonOptions}
                onClick={() => focusField(field)}
              />
            );
          })}
          {polygon && (
            <Polygon
              path={parsePolygon(polygon)}
              editable={false}
              options={polygonOptions}
            />
          )}
          {/* <Polygon path={vojvodaStepa} />
        <Polygon path={srpskaCrnja} options={{ fillColor: "red" }} />
        <Polygon path={randomNJ} options={{ fillColor: "blue" }} />
        <Polygon path={klindra} options={{ fillColor: "silver" }} /> */}
        </>
      </GoogleMap>
      {hoveredField && <div>{hoveredField.name} asd</div>}
    </div>
  ) : null;
};

export default React.memo(MapComponent);
