import { ConfigProvider } from "antd";
import srRs from "antd/es/locale/sr_RS";
import React from "react";
import ReactDOM from "react-dom/client";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import { AppProvider } from "./AppContext";
import { AppRoutes } from "./AppRoutes";
import "./i18n";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
// const language = navigator.language || "en-US";
const routes = createRoutesFromElements(AppRoutes);

const router = createBrowserRouter(routes);
root.render(
  <React.StrictMode>
    <AppProvider>
      <ConfigProvider locale={srRs}>
        <RouterProvider router={router} />
      </ConfigProvider>
    </AppProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
