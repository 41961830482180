import { Table, TableColumnsType, TableProps } from "antd";
import React, { useEffect, useState } from "react";
import { useAppContext } from "../../AppContext";
import { findCrop } from "../crop/CropStatic";
import { sqMeterToUnit } from "../fields/utils";
import { getSeasonLabel } from "../seasons/SeasonUtil";
import { FieldSeasonDB } from "./FieldSeasonDB";
import { FieldSeasonForm } from "./FieldSeasonForm";
import { FieldSeasonModel } from "./FieldSeasonModel";
const FieldSeasonsTable: React.FC<{
  fieldId: string;
}> = ({ fieldId }) => {
  const [data, setData] = useState<FieldSeasonModel[]>([]);
  const { season, areaUnit, setAreaUnit } = useAppContext();

  const columns: TableColumnsType<FieldSeasonModel> = [
    {
      title: "Season",
      dataIndex: "season",
      sortOrder: "ascend",
      sorter: (a, b) => a.season.localeCompare(b.season),
      width: "20%",
      render: (value, record) => {
        return <>{getSeasonLabel(value)}</>;
      },
    },
    {
      title: "Kultura",
      dataIndex: "crop",
    },
    {
      title: "Površina",
      dataIndex: "area",
      onHeaderCell: () => ({
        onClick: () => {
          if (areaUnit === "ha") {
            setAreaUnit("kj");
          } else {
            setAreaUnit("ha");
          }
        },
      }),
      onCellClick: (record) => {
        if (areaUnit === "ha") {
          setAreaUnit("kj");
        } else {
          setAreaUnit("ha");
        }
      },
      sorter: (a, b) => a.area - b.area,
      render: (_, record) => {
        return sqMeterToUnit(record.area, areaUnit).toFixed(2);
      },
    },
    {
      title: "Kultura",
      dataIndex: "crop",

      render: (value, record) => {
        return findCrop(record.crop)?.title_sr;
      },
    },
  ];

  const onChange: TableProps<FieldSeasonModel>["onChange"] = (
    pagination,
    filters,
    sorter,
    extra
  ) => {
    console.log("params", pagination, filters, sorter, extra);
  };
  useEffect(() => {
    FieldSeasonDB.where([
      {
        field: "season",
        operator: "==",
        value: season,
      },
      {
        field: "fieldId",
        operator: "==",
        value: fieldId,
      },
    ]).then((data) => setData(data));
  }, [fieldId, season]);

  return (
    <>
      <Table
        bordered
        size="small"
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={data}
        onChange={onChange}
        pagination={false}
        expandable={{
          expandedRowRender: (record) => (
            <FieldSeasonForm model={record} mode="view" />
          ),
          // rowExpandable: (record) => record.name !== "Not Expandable",
        }}
      />
    </>
  );
};

export default FieldSeasonsTable;
